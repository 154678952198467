import { useAuthStore } from '~/stores/useAuthStore'

export const useLogout = () => {
  const auth = useAuthStore()
  
  /**
   * Handles the complete logout process including cleanup and navigation
   */
  const logout = async () => {
    try {
      const storedToken = localStorage.getItem('token')
      
      // Call server to invalidate token if it exists
      if (storedToken) {
        await useApiFetch('/api/logout', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${storedToken}`,
          }
        }).catch(error => {
          console.error('Logout API call failed:', error)
        })
      }

      // Clear all auth-related localStorage items
      localStorage.removeItem('token')
      localStorage.removeItem('token_expiration')
      
      // Clear any potential social auth data
      localStorage.removeItem('google_auth_state')
      localStorage.removeItem('facebook_auth_state')
      
      // Reset auth store state
      auth.logout()

      // Clear all cookies
      document.cookie.split(';').forEach(cookie => {
        const name = cookie.split('=')[0].trim()
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`
      })

      // Navigate to home page
      await navigateTo('/')
      
    } catch (error) {
      console.error('Error during logout cleanup:', error)
      // Ensure critical state is reset even if cleanup fails
      auth.user = null
      auth.token = null
      
      // Still try to navigate home
      await navigateTo('/')
      
      throw error // Re-throw for error handling in components
    }
  }

  return {
    logout
  }
} 