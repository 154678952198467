<script setup lang="ts">
import { ref, onMounted, onUnmounted, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router'
import { BellIcon, BellRingIcon, ArrowRightIcon } from 'lucide-vue-next'
import { useEventListener } from '@vueuse/core'
import { useLogout } from '~/composables/useLogout'

const route = useRoute()
const baseUrl = window.location.origin
const headerLink = `${baseUrl}${route.fullPath}`
const auth = useAuthStore()
const toast = useToast()

type Notification = {
  label: string
  click: () => void
  class?: string
}

const open = ref(false)
const notifications = ref<Notification[]>([])
const username = ref(auth.user?.first_name || '')

const { logout } = useLogout()

async function handleLogout() {
  try {
    await logout()
  } catch (error) {
    console.error('Logout failed:', error)
    // Show error toast/notification to user
    toast.add({
      title: 'Error',
      description: 'Failed to logout properly. Please try again.',
      icon: 'i-heroicons-exclamation-circle',
      color: 'red'
    })
  }
}

onMounted(async () => {
  await fetchUserNotifications()
  useEventListener(document, 'keydown', (e: KeyboardEvent) => {
    if (e.key === 'Escape') closeNotifications()
  })
})

onUnmounted(() => {
  notifications.value = []
})

onBeforeUnmount(() => {
  useEventListener(document, 'keydown', (e: KeyboardEvent) => {
    if (e.key === 'Escape') closeNotifications()
  })
})

type NotificationResponse = {
  id: string
  label: string
}

async function fetchUserNotifications() {
  try {
    const fetchedNotifications = await useFetchUserNotifications() || []
    notifications.value = []

    if (Array.isArray(fetchedNotifications.responseRef.value)) {
      for (const notification of fetchedNotifications.responseRef.value) {
        notifications.value.push({
          label: notification.label,
          click: () => {
            markUserNotificationAsRead(notification.id)
          }
        })
      }

      notifications.value.push({
        label: "Clear all",
        click: async () => {
          await useMarkAllUserNotificationsAsRead()
          notifications.value = []
          await fetchUserNotifications()
        },
        class: 'text-red-500 font-bold'
      })
    }
  } catch (error) {
    console.error("Failed to fetch user profile:", error)
    notifications.value = []
  }
}

async function markUserNotificationAsRead(notificationId: string) {
  try {
    await useMarkUserNotificationAsRead({ id: notificationId })
    notifications.value = []
    await fetchUserNotifications()
  } catch (error) {
    console.error("Failed to mark user notification as read:", error)
  }
}

function closeNotifications() {
  open.value = false
}
</script>

<template>
  <header class="bg-white shadow-md">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between items-center py-4">
        <!-- Hide logo on small screens -->
        <NuxtLink :to="headerLink" class="text-2xl font-bold text-purple-900 hidden md:block">
          SYNERGOS
        </NuxtLink>

        <!-- Welcome message on the left for mobile -->
        <div class="flex items-center space-x-4">
          <div class="font-semibold text-gray-700 md:ml-auto">
            Welcome {{ username }}!
          </div>

          <div class="relative">
            <button @click="open = !open"
              class="p-2 rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
              <BellIcon v-if="notifications.length === 0" class="h-6 w-6 text-gray-500" />
              <BellRingIcon v-else class="h-6 w-6 text-red-500" />
            </button>

            <div v-if="open" v-click-outside="closeNotifications"
              class="absolute right-0 mt-2 w-64 bg-white rounded-md shadow-lg py-1 z-10 max-h-[300px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
              <div v-for="(notification, index) in notifications" :key="index"
                class="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer" @click="notification.click">
                <span :class="notification.class">{{ notification.label }}</span>
              </div>
              <div v-if="notifications.length === 0" class="px-4 py-2 text-sm text-gray-500">
                No notifications
              </div>
            </div>
          </div>

          <!-- Smaller Logout Button on Mobile -->
          <button @click="handleLogout"
            class="flex items-center px-2 py-1 md:px-3 md:py-1.5 border border-transparent text-xs md:text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
            Logout
            <ArrowRightIcon class="ml-1 -mr-1 h-4 w-4 md:h-5 md:w-5" />
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>
/* Custom scrollbar styling */
.scrollbar-thin::-webkit-scrollbar {
  width: 6px;
}

.scrollbar-thin::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background: #d1d5db;
  border-radius: 3px;
}

.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  background: #9ca3af;
}
</style>